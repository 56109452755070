@import "tailwindcss/base";
@import "tailwindcss/components";

@import "additional-styles/utility-patterns.css";
@import "additional-styles/theme.css";
@import "tailwindcss/utilities";
body {
    font-family: "Outfit", sans-serif;
  }
  
  h1 {
    font-family: "Onest", sans-serif;
  }
  
  h2 {
    font-family: "Gantari", sans-serif;
  }
  